<template>
  <div>
    <div class="items-container">
      <div v-for="item in displayedItems" :key="item.id" class="single-item" @click="divClicked(item)">
        <div class="item-header">
          <img :src="item.exteriorPhotoUrl" class="item-photo">
          <div class="item-info">
            <div class="aircraft-info">
              <div class="left-side-info">
                <h3 id="aircraft-type-id">
                  {{ item.aircraftType }}
                </h3>
                <div class="aircraft-category">
                  {{ item.aircraftCategory }}
                </div>
              </div>
              <div class="right-side-info">
                <h3 id="price-id">
                  {{ item.price ? formatCurrency(item.price) : "~ " + formatCurrency(item.calculatedPrice) }}
                </h3>
                <div class="max-capacity">
                  <font-awesome-icon class="fa-sm" icon="user"/>
                  <p class="capacity-text">{{ item.maxCapacity === null ? "N/A" : item.maxCapacity }}</p>
                </div>
              </div>
            </div>
            <div class="flight-info">
              <div class="start-info">
                <div class="start-datetime">
                  <p>{{ formatDatetime(item.startTime) }}</p>
                </div>
                <div class="start-location">
                  <p>{{ item.startAirport.icao }}, {{ formatAirportName(item.startAirport.city) }}</p>
                </div>
              </div>
              <div class="icon-and-duration">
                <font-awesome-icon class="fa-1x" icon="plane"/>
                <div>{{ item.flightMinutes ? formatHours(item.flightMinutes) : "N/A" }}</div>
              </div>
              <div class="end-info">
                <div class="end-datetime">
                  <p>{{ formatDatetime(item.endTime) }}</p>
                </div>
                <div class="end-location">
                  <p>{{ item.endAirport.icao }}, {{ formatAirportName(item.endAirport.city) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-button v-if="hasMoreItems" @click="loadMore" class="is-primary is-fullwidth">Load More</b-button>
  </div>
</template>

<script>
import utils from "@/utils";

export default {
  name: "ListDisplay",
  props: {
    items: Array
  },
  data() {
    return {
      displayedItemsCount: 10
    };
  },
  computed: {
    hasMoreItems() {
      return this.displayedItemsCount < this.items.length;
    },
    displayedItems() {
      return this.items.slice(0, this.displayedItemsCount);
    }
  },
  methods: {
    formatDatetime: utils.formatDatetime,
    formatCurrency: utils.formatCurrency,
    formatHours: utils.formatHours,
    formatAirportName: utils.formatAirportName,
    divClicked(item) {
      utils.simulateSubmit(this, item.id, "empty-leg-" + item.id)
      console.log(item);
    },
    loadMore() {
      this.displayedItemsCount += 10;
    },
  },
};

</script>

<style lang="scss" scoped>
@import "~@/assets/css/skin.scss";

.item-photo {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
  display: flex;
}

.item-info {
  padding: 0.8rem;
}

.aircraft-info {
  display: flex;
  justify-content: space-between;
}

.left-side-info {
  text-align: left;
}

#aircraft-type-id {
  margin-bottom: 0;
  font-size: 1rem;
  color: #393b41;
}

.aircraft-category {
  font-size: 0.8rem;
  color: #72757e;
}

.right-side-info {
  text-align: right;
}

.max-capacity {
  display: flex;
  font-size: 0.8rem;
  justify-content: flex-end;
}

.fa-sm {
  margin-right: 5px;
  align-self: center;
}

.capacity-text {
  display: table-cell;
  vertical-align: middle;
}

#price-id {
  font-size: 1rem;
  color: hsl(0, 0%, 21%);
  margin: 0;
}

.flight-info {
  padding-top: 0.1rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.7rem;
  position: relative;
}

.icon-and-duration {
  margin-top: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.fa-1x {
  margin: 0 0.5rem;
  align-self: center;
}

.end-info {
  text-align: right;
}

//////////////////////////////

.items-container {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.single-item {
  width: 100%;
  margin-bottom: 20px;
}

.item-header {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 10px;
  gap: 1rem;
  align-items: center;
  background-color: #f8f8f8; /* Change the background color as needed */
}

.is-primary {
  border-radius: 100px;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.12);
  padding-bottom: 1.5em;
  padding-top: 1.5em;
  font-weight: bold;
}

</style>
