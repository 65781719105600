export default {
    formatDatetime(datetime) {
        datetime = new Date(datetime);
        return datetime.toLocaleString('en-US',
            {
                day: "numeric",
                month: "short",
                // year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                timeZone: 'America/New_York'
            });
    },
    formatHours(minutes) {
        let hours = Math.floor(minutes / 60);
        let mins = minutes % 60;
        return `${hours}h${mins}`;
    },
    formatAirportName(str) {
        if (str.length > 8) {
            return str.substring(0, 6) + '...';
        } else {
            return str;
        }
    },
    simulateSubmit(instance, inputId, nextScreen = null, transitionDirection = "right") {
        let submitButtonValue = {[inputId]: {value: nextScreen}};

        let payload = {
            type: "template_submit",
            screenId: instance.$store.state.activeScreenId,
            buttonId: inputId,
            data: {
                ...instance.$store.state.inputs,
                ...submitButtonValue,
            },
        };
        instance.$store.commit("setTransitionDirection", transitionDirection);
        payload.data.history = instance.$store.state.history;

        if (nextScreen) {
            instance.$store.commit("setInputValue", {
                inputId: inputId,
                value: {value: nextScreen},
            });
            instance.$store.commit("setActiveScreen", {
                buttonId: inputId,
                nextScreenId: nextScreen,
            });
            instance.$store.commit("emitResponse", {
                payload: JSON.stringify(payload),
                socket: instance.$socket,
            });
        } else {
            instance.$store.commit("pushActiveStateToHistory", {buttonId: inputId});
            instance.$store.commit("submitResponse", {
                payload: JSON.stringify(payload),
                socket: instance.$socket,
            });
        }
    },
    formatCurrency(
        amount,
        currency = "USD",
        decimalPlaces = 0,
        locale = "en-US"
    ) {
        if (amount === null || amount === undefined || isNaN(amount)) {
            return "$ N/A";
        }
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency: currency,
            maximumFractionDigits: decimalPlaces,
        }).format(amount);
    },
    debounce(func, delay) {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    },
}