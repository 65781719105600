<template>
  <section>
    <b-field v-model="inputValue">
      <b-autocomplete
          :loading="isFetching"
          v-model="query"
          :data="airports"
          :placeholder="placeholder"
          :clear-on-select="true"
          @input="debouncedFetchAirports"
          @select="appendAirport"
          :disabled="selectedMaximumNumberOfAirports">
        <template v-slot="props">
          <div class="media">
            <div class="media-left">
              <font-awesome-icon class="fa-2x" icon="plane-departure"/>
            </div>
            <div class="media-content">
              <div>
                <strong>{{ props.option.name }}</strong>
              </div>
              <div>
                <small style="color: #72757e">{{ createDisplayName(props.option) }}</small>
              </div>
            </div>
          </div>
        </template>
      </b-autocomplete>
    </b-field>
    <div class="selected-airports">
      <div v-for="airport in selectedAirports" :key="airport.icao" class="selected-airport-item">
        <p style="color: white" class="selected-info">{{ airport.icao }} </p>
        <font-awesome-icon style="color: white" class="remove-item" @click="removeAirport(airport)" icon="minus"/>
      </div>
    </div>
  </section>
</template>

<script>

import debounce from "buefy/src/utils/debounce";
import BaseValue from "armory-sdk/src/components/base/BaseValue.vue";

export default {
  name: "AutocompleteSearch",
  extends: BaseValue,
  data() {
    return {
      query: '',
      isFetching: false,
      airports: [],
      filteredDataArray: [],
      selectedAirports: [],
      debouncedFetchAirports: null
    };
  },
  props: {
    placeholder: String,
    minimumNumberOfAirports: {
      type: Number,
      default: 2,
    },
    maximumNumberOfAirports: {
      type: Number,
      default: 7,
    },
    value: {
      type: Array,
      default: [],
    },
  },
  created() {
    this.debouncedFetchAirports = debounce(this.fetchAirports, 500);
  },
  mounted: function () {
    if (this.$store.state.inputs[this.inputId].value !== null) {
      this.selectedAirports = this.$store.state.inputs[this.inputId];
    }
    if (this.value) {
      this.selectedAirports = this.value.map(airport => ({
        name: airport.name,
        icao: airport.icao,
        iata: airport.iata,
        city: airport.city,
        country: airport.country,
        state: airport.state,
      }));
    }
  },
  computed: {
    selectedMaximumNumberOfAirports() {
      return this.selectedAirports.length >= this.maximumNumberOfAirports;
    },
    selectedMinimumNumberOfAirports() {
      return this.selectedAirports.length >= this.minimumNumberOfAirports;
    }
  },
  methods: {
    fetchAirports() {
      if (!this.query) {
        this.filteredDataArray = [];
        return;
      }
      this.isFetching = true;
      fetch('https://orbyjetsapp.com/api/airport/search?query=' + this.query, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      })
          .then(response => response.json())
          .then(data => {
            this.airports = this.createAirportObjects(this.filterAirports(data));
          })
          .catch(error => console.error('Error:', error))
          .finally(() => this.isFetching = false);
    },
    filterAirports(data) {
      return data.filter(airport => airport.name && airport.icao && airport.name.trim() !== '' && airport.icao.trim() !== '' && !this.selectedAirports.some(selectedAirport => selectedAirport.icao === airport.icao));
    },
    createAirportObjects(data) {
      return data.map(airport => {
        const {name, icao, iata, city, province, country} = airport;

        return {
          name,
          icao,
          iata,
          city,
          country: country?.name,
          state: province?.code,
        };
      });
    },
    createDisplayName(airport) {
      return airport.icao +
          (airport.city != null ? ", " + airport.city : "") +
          (airport.state != null ? ", " + airport.state : "") +
          (airport.country != null ? ", " + airport.country : "");
    },
    appendAirport(airport) {
      if (airport) {
        this.selectedAirports.push(airport);
      }
      this.$store.commit("setInputValue", {
        inputId: this.inputId,
        value: this.selectedAirports,
      });
      if (this.required) {
        this.$store.commit("setInputValidation", {
          inputId: this.inputId,
          valid: this.selectedMinimumNumberOfAirports,
        });
      }
    },
    removeAirport(airport) {
      this.selectedAirports = this.selectedAirports.filter(selectedAirport => selectedAirport !== airport);
      this.$store.commit("setInputValue", {
        inputId: this.inputId,
        value: this.selectedAirports,
      });
      if (this.required) {
        this.$store.commit("setInputValidation", {
          inputId: this.inputId,
          valid: this.selectedMinimumNumberOfAirports,
        });
      }
    }
  }
};

</script>

<style scoped lang="scss">

.selected-airports {
  display: flex;
  flex-wrap: wrap;
}

.selected-airport-item {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #3f166d;
  margin: 10px;
}

.selected-info {
  padding: 5px;
  margin-bottom: 0 !important;
  text-align: center;
  justify-content: center;
}

.media {
  align-items: center;
}

.remove-item {
  color: #72757e;
  margin: 0 5px;
}

</style>
