<script>
import Armory from "armory-sdk/src/Armory";

import AvailableSchedules from "@/components/AvailableSchedules.vue";
import IdScanner from "./components/IdScanner";
import ListDisplay from "@/components/ListDisplay.vue";
import ItemInfo from "@/components/ItemInfo.vue";
import AutocompleteSearch from "@/components/AutocompleteSearch.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "App",
  extends: Armory,
  methods: {
    getCustomTemplates() {
      return {};
    },
    getCustomComponents() {
      return {
        AvailableSchedules,
        IdScanner,
        ListDisplay,
        ItemInfo,
        AutocompleteSearch,
        Spinner,
      };
    },
    // eslint-disable-next-line no-unused-vars
    preReceivedSpecificationTrigger(receivedData) {
    },
    postReceivedSpecificationTrigger() {
    }
  }
};
</script>
