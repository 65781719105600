<template>
  <div class="component content" v-if="item">
    <b-carousel :autoplay="false" :arrow="false" class="photo-container">
      <b-carousel-item class="item-photo" v-for="(photo, index) in [item.exteriorPhotoUrl, item.interiorPhotoUrl]"
                       :key="index">
        <b-image :src="photo"></b-image>
      </b-carousel-item>
    </b-carousel>
    <div class="block">
      <div class="aircraft-info" style="margin-bottom: 1em;">
        <div class="left-side-info">
          <h4 style="margin-bottom: 0;">
            {{ item.aircraftType }}
          </h4>
          <div class="aircraft-category">
            {{ item.aircraftCategory }}
          </div>
        </div>
        <div class="right-side-info">
          <h4 id="price-id" style="margin-bottom: 0;">
            {{ item.price ? formatCurrency(item.price) : "~" + formatCurrency(item.calculatedPrice) }}
          </h4>
          <div class="max-capacity">
            <font-awesome-icon class="fa-sm" icon="user"/>
            <p class="capacity-text">{{ item.maxCapacity === null ? "N/A" : item.maxCapacity }}</p>
          </div>
        </div>
      </div>
      <div class="flight-info">
        <div class="start-info">
          <div class="start-datetime">
            <p>{{ formatDatetime(item.startTime) }}</p>
          </div>
          <div class="start-location">
            <p>{{ item.startAirport.icao }}, {{ item.startAirport.city }}</p>
          </div>
        </div>
        <div class="icon-and-duration">
          <font-awesome-icon class="fa-1x" icon="plane"/>
          <div>{{ item.flightMinutes ? formatHours(item.flightMinutes) : "N/A" }}</div>
        </div>
        <div class="end-info">
          <div class="end-datetime">
            <p>{{ formatDatetime(item.endTime) }}</p>
          </div>
          <div class="end-location">
            <p>{{ item.endAirport.icao }}, {{ item.endAirport.city }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="additional-info">
      <div class="row">
        <p><strong>Distance:</strong></p>
        <p>{{ item.distanceNM ? item.distanceNM + " NM" : "N/A" }}</p>
      </div>
      <div class="row">
        <p><strong>Year of make:</strong></p>
        <p>{{ item.yearOfMake ? item.yearOfMake : "N/A" }}</p>
      </div>
      <div class="row">
        <p><strong>Amenities:</strong></p>
        <p v-if="item.amenities" style="margin-left: 0.3rem">{{ item.amenities.length > 0 ? item.amenities.join(", ") : "Not Available" }}</p>
        <p v-else style="margin-left: 0.3rem">Not Available</p>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/utils";

export default {
  name: "ItemInfo",
  props: {
    item: Object,
  },
  methods: {
    formatDatetime: utils.formatDatetime,
    formatHours: utils.formatHours,
    formatCurrency: utils.formatCurrency,
  },

};
</script>

<style scoped>
@import 'buefy/dist/buefy.css';

.photo-container {
  margin-bottom: 1em;
}

.item-photo {
  width: 100%;
  height: auto;
}

.aircraft-info {
  display: flex;
  margin-bottom: 1em;
  justify-content: space-between;
}

.flight-info {
  display: flex;
  justify-content: space-between;
  font-size: 0.7rem;
  position: relative;
}

.icon-and-duration {
  margin-top: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.fa-1x {
  margin: 0 0.5rem;
  align-self: center;
}

.start-info {
  max-width: 8rem;
}

.end-info {
  text-align: right;
  max-width: 8rem;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.right-side-info {
  text-align: right;
}

.fa-sm {
  margin-right: 0.2em;
}

#price-id {
  font-size: 1.25em;
  color: hsl(0, 0%, 21%);
}

.max-capacity {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.capacity-text {
  font-size: 1rem;
}

.row > :last-child {
  text-align: right;
}

.row p {
  font-size: 14px;
}

</style>

<style>

.b-image-wrapper {
  margin: 0 !important;
}

.carousel-indicator {
  padding-bottom: 0.8rem !important;
}

</style>
